import { LockOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import React from 'react';

interface InputPercentProps {
    value?: string;
    onChange?: (value: string) => void;
    locked?: boolean;
}

const InputPercent: React.FC<InputPercentProps> = ({ value = '', onChange, locked }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.replace(/[^0-9.]/g, '');
        if (val === '' || (parseFloat(val) >= 0 && parseFloat(val) <= 100)) {
            onChange?.(val);
        }
    };

    const displayValue = value !== null && value !== undefined ? `${value}%` : '';

    const inputWithLock = (
        <Input style={{ width: '100%' }} value={displayValue} onChange={handleChange} suffix={locked && <LockOutlined />} />
    );

    return locked ? (
        <Tooltip title='Locked'>
            <div>{inputWithLock}</div>
        </Tooltip>
    ) : (
        inputWithLock
    );
};

export default InputPercent;
