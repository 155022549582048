import { Divider, Form, Input, Radio, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import ProductCategorySelect from 'components/lib/ProductCategorySelect';
import { NonMedical } from 'services/nonMedical.service';

interface NonMedicalTabProps {
    nonMedicalToUpdate: NonMedical | null;
    form: FormInstance;
}

export const NonMedicalTab = ({ nonMedicalToUpdate, form }: NonMedicalTabProps) => {
    return (
        <>
            {nonMedicalToUpdate && <HiddenInput fieldName='id' initialValue={nonMedicalToUpdate.id} />}

            <Form.Item label='Name' name='name' initialValue={nonMedicalToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label='Unit' name='unit' initialValue={nonMedicalToUpdate?.unit || 'unit'} rules={[{ required: true }]}>
                <Select>
                    {['unit', 'dollar'].map((name) => (
                        <Select.Option key={name} value={name}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label='Has Reason'
                name='has_reason'
                initialValue={nonMedicalToUpdate?.has_reason ?? false}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label='Order From Estimate'
                name='order_from_estimate'
                initialValue={nonMedicalToUpdate?.order_from_estimate ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider />

            <Form.Item label='Product Category' name='product_category_id' initialValue={nonMedicalToUpdate?.product_category_id ?? null}>
                <ProductCategorySelect
                    defaultValue={nonMedicalToUpdate?.product_category_id ?? null}
                    onChange={(value) => form.setFieldsValue({ product_category_id: value })}
                />
            </Form.Item>
        </>
    );
};
