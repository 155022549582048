import { EditOutlined, MailOutlined, PhoneOutlined, PrinterOutlined, ProfileOutlined } from '@ant-design/icons';
import { Divider, Tabs, Typography } from 'antd';
import { Breadcrumb, Col, Content, Row } from 'components/lib';
import { useDependantBreadcrumbs } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRdvmQuery } from 'services/rdvm.service';
import { format } from 'utils/helpers';
import { RdvmClinic } from 'utils/types';
import ClinicNotes from './ClinicNotes';
import EditClinicModal from './EditClinicModal';
import clinicDetailStyles from './index.module.css';

const { TabPane } = Tabs;

const ClinicDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [clinic, setClinic] = useState<RdvmClinic | null>(null);
    const [openEditClinicContactModal, setOpenEditClinicContactModal] = useState(false);
    const { data: rdvmDetails } = useGetRdvmQuery(id);

    let [breadcrumbs] = useDependantBreadcrumbs(['Marketing', 'Clinics'], (clinic) => clinic.name, clinic);

    const formattedAddress = [rdvmDetails?.street, rdvmDetails?.city, rdvmDetails?.state, rdvmDetails?.zipcode]
        .filter((detail) => !!detail)
        .join(', ');

    return (
        <>
            <Breadcrumb path={breadcrumbs} />
            <Content>
                <div>
                    <Typography.Title className={clinicDetailStyles.clinicTitle} level={2}>
                        {rdvmDetails?.display_name}{' '}
                        <EditOutlined style={{ fontSize: 15 }} onClick={() => setOpenEditClinicContactModal(true)} />
                    </Typography.Title>
                    <Typography.Text type='secondary' className={clinicDetailStyles.addressSection}>
                        {formattedAddress}
                    </Typography.Text>
                    <Divider />
                    <Row gutter={50}>
                        <Col className={`gutter-row ${clinicDetailStyles.contactSection}`} xs={24} xl={12}>
                            <Row>
                                <Col className='gutter-row' xs={24} md={8} xl={24}>
                                    <MailOutlined className={clinicDetailStyles.contactIcon} />
                                    {rdvmDetails?.email}
                                </Col>
                                <Col className='gutter-row' xs={24} md={8} xl={24}>
                                    <PhoneOutlined className={clinicDetailStyles.contactIcon} />
                                    {format.formatPhoneNumber(rdvmDetails?.phone_number)}
                                </Col>

                                <Col className='gutter-row' xs={24} md={8} xl={24}>
                                    <PrinterOutlined className={clinicDetailStyles.contactIcon} />
                                    {format.formatPhoneNumber(rdvmDetails?.fax_number ?? undefined)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Tabs defaultActiveKey='1'>
                        <TabPane
                            tab={
                                <span>
                                    <ProfileOutlined />
                                    Notes
                                </span>
                            }
                            key='3'
                        >
                            <ClinicNotes meetingNotes={rdvmDetails?.meetings} />
                        </TabPane>
                    </Tabs>
                </div>
            </Content>

            <EditClinicModal
                isOpen={openEditClinicContactModal}
                setIsOpen={setOpenEditClinicContactModal}
                initialContactState={rdvmDetails}
                clinicId={id}
            />
        </>
    );
};

export default ClinicDetail;
