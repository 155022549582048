import { DeleteOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, FormInstance, Input, Radio, Row, Select, Table, Typography } from 'antd';
import { convertFromMicro } from 'components/views/MedicinesListing/MedicationUnitSelect';
import React, { ElementRef, useMemo, useRef, useState } from 'react';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { isInstanceOfMedication } from 'services/medicines.service';
import { FLOAT_REGEX, POSITIVE_INTEGER, SIGNED_FLOAT_REGEX } from 'utils/consts';
import { capitalizeStr, formatCurrency, roundTo } from 'utils/helpers/format';
import { calculatePriceCentsFromMarkup, getHospitalFromHospitalId } from 'utils/helpers/general';
import { CatalogItemToUpdate, Hospital, PriceOverride, QueryResponse } from 'utils/types';
import HospitalColumn from './HospitalColumn';

interface PricingTabProps {
    catalogItemToUpdate: CatalogItemToUpdate | null;
    priceOverrides: PriceOverride[];
    setPriceOverrides: (priceOverrides: PriceOverride[]) => void;
    isPricingRelative: boolean;
    setIsPricingRelative: (isPricingRelative: boolean) => void;
    newRowId: number;
    setNewRowId: (newRowId: number) => void;
    form: FormInstance;
    updateRelativeOverrides: () => void;
    updateAbsoluteOverrides: (changedToSerial: boolean) => void;
    typeId: string;
}

export const PricingTab = ({
    catalogItemToUpdate,
    priceOverrides,
    setPriceOverrides,
    isPricingRelative,
    setIsPricingRelative,
    newRowId,
    setNewRowId,
    form,
    updateRelativeOverrides,
    updateAbsoluteOverrides,
    typeId,
}: PricingTabProps) => {
    const isSeriable = ['T', 'D', 'M'].includes(typeId);
    const [isSerial, setIsSerial] = useState(catalogItemToUpdate?.serial ?? false);
    const [serialHours, setSerialHours] = useState<number | undefined>(catalogItemToUpdate?.serial_hours);
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});
    const notSetHospitals = hospitals?.filter(
        (hospital) => !priceOverrides.some((priceOverride) => priceOverride.hospital_id?.toString() === hospital.id.toString()),
    );
    const catalogItemName = Form.useWatch('name', form);
    const dosingUnit = Form.useWatch('dosing_unit', form);
    const calculatorType = Form.useWatch('calculator_type', form);
    const recurring = Form.useWatch('recurring', form);
    const basePriceInputRef = useRef<ElementRef<typeof Input>>(null);
    const isExistingMedication = isInstanceOfMedication(catalogItemToUpdate);
    const [pricingUnit, setPricingUnit] = useState<string | null>(isExistingMedication ? catalogItemToUpdate.pricing_unit : null);

    const absolutePricingColumns: any[] = useMemo(() => {
        if (isSerial) {
            return [
                {
                    title: 'Location Name',
                    dataIndex: 'hospital_id',
                    key: 'hospital_id',
                    width: '35%',
                    sorter: (a: PriceOverride, b: PriceOverride) => {
                        const aName = getHospitalFromHospitalId(hospitals, a.hospital_id)?.display_name ?? '';
                        const bName = getHospitalFromHospitalId(hospitals, b.hospital_id)?.display_name ?? '';
                        return aName.localeCompare(bName);
                    },
                    render: (hospital_id: number, record: PriceOverride) => {
                        return (
                            <HospitalColumn
                                formName={`location_${record.override_price_id}`}
                                initialValue={hospital_id}
                                hospitals={hospitals}
                                notSetHospitals={notSetHospitals}
                                onChange={(hospital_id) => {
                                    updateHospitalId(record.override_price_id, hospital_id);
                                }}
                            />
                        );
                    },
                },
                {
                    title: 'Price',
                    dataIndex: 'override_price_cents',
                    key: 'override_price_cents',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) => (a.override_price_cents ?? 0) - (b.override_price_cents ?? 0),
                    render: (override_price_cents: number, record: PriceOverride) => {
                        return (
                            <PriceColumn
                                override_price_id={record.override_price_id}
                                hospital_id={record.hospital_id}
                                initialValue={override_price_cents}
                                isEditing={record.isEditing}
                                isPricingRelative={isPricingRelative}
                                form={form}
                                updateOverridePrice={updateOverridePrice}
                                serial={false}
                            />
                        );
                    },
                },
                {
                    title: 'Serial Price Per Hour',
                    dataIndex: 'override_serial_price_cents',
                    key: 'override_serial_price_cents',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) =>
                        (a.override_serial_price_cents ?? 0) - (b.override_serial_price_cents ?? 0),
                    render: (override_serial_price_cents: number, record: PriceOverride) => {
                        return (
                            <PriceColumn
                                override_price_id={record.override_serial_price_id}
                                hospital_id={record.hospital_id}
                                initialValue={override_serial_price_cents ? override_serial_price_cents : undefined}
                                isEditing={record.isEditing}
                                isPricingRelative={isPricingRelative}
                                form={form}
                                updateOverridePrice={updateOverridePrice}
                                serial={true}
                            />
                        );
                    },
                },
                {
                    title: 'Serial Price Total',
                    dataIndex: 'override_serial_price_cents_total',
                    key: 'override_serial_price_cents_total',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) =>
                        (a.override_serial_price_cents ?? 0) - (b.override_serial_price_cents ?? 0),
                    render: (override_serial_price_cents: number, record: PriceOverride) => {
                        const basePriceCentsSerial = record.override_serial_price_cents;
                        const basePriceCentsSerialTotal = basePriceCentsSerial && serialHours ? basePriceCentsSerial * serialHours : 0;
                        return (
                            <Typography.Text disabled={!isSerial}>
                                {formatCurrency(roundTo(basePriceCentsSerialTotal / 100, 2))}
                            </Typography.Text>
                        );
                    },
                },

                {
                    title: '',
                    key: 'delete',
                    align: 'right',
                    width: '16px',
                    render: (record: PriceOverride) => {
                        return (
                            <Button
                                style={{ border: 0, height: 'auto', padding: '2px 4px', lineHeight: 1 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removePriceOverride(record.override_price_id);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        );
                    },
                },
            ];
        }
        return [
            {
                title: 'Location Name',
                dataIndex: 'hospital_id',
                key: 'hospital_id',
                width: '50%',
                sorter: (a: PriceOverride, b: PriceOverride) => {
                    const aName = getHospitalFromHospitalId(hospitals, a.hospital_id)?.display_name ?? '';
                    const bName = getHospitalFromHospitalId(hospitals, b.hospital_id)?.display_name ?? '';
                    return aName.localeCompare(bName);
                },
                render: (hospital_id: number, record: PriceOverride) => {
                    return (
                        <HospitalColumn
                            formName={`location_${record.override_price_id}`}
                            initialValue={hospital_id}
                            hospitals={hospitals}
                            notSetHospitals={notSetHospitals}
                            onChange={(hospital_id) => {
                                updateHospitalId(record.override_price_id, hospital_id);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Price',
                dataIndex: 'override_price_cents',
                key: 'override_price_cents',
                align: 'right',
                width: '25%',
                sorter: (a: PriceOverride, b: PriceOverride) => (a.override_price_cents ?? 0) - (b.override_price_cents ?? 0),
                render: (override_price_cents: number, record: PriceOverride) => {
                    return (
                        <PriceColumn
                            override_price_id={record.override_price_id}
                            hospital_id={record.hospital_id}
                            initialValue={override_price_cents}
                            isEditing={record.isEditing}
                            isPricingRelative={isPricingRelative}
                            form={form}
                            updateOverridePrice={updateOverridePrice}
                            serial={false}
                        />
                    );
                },
            },
            {
                title: '',
                key: 'delete',
                align: 'right',
                width: '16px',
                render: (record: PriceOverride) => {
                    return (
                        <Button
                            style={{ border: 0, height: 'auto', padding: '2px 4px', lineHeight: 1 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                removePriceOverride(record.override_price_id);
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    );
                },
            },
        ];
    }, [hospitals, priceOverrides, isSerial, serialHours]);

    const relativePricingColumns: any[] = useMemo(() => {
        if (isSerial) {
            return [
                {
                    title: 'Location Name',
                    dataIndex: 'hospital_id',
                    key: 'hospital_id',
                    width: '25%',
                    sorter: (a: PriceOverride, b: PriceOverride) => {
                        const aName = getHospitalFromHospitalId(hospitals, a.hospital_id)?.display_name ?? '';
                        const bName = getHospitalFromHospitalId(hospitals, b.hospital_id)?.display_name ?? '';
                        return aName.localeCompare(bName);
                    },
                    render: (hospital_id: number, record: PriceOverride) => {
                        return (
                            <HospitalColumn
                                formName={`location_${record.override_price_id}`}
                                initialValue={hospital_id}
                                hospitals={hospitals}
                                notSetHospitals={notSetHospitals}
                                onChange={(hospital_id) => {
                                    updateHospitalId(record.override_price_id, hospital_id);
                                }}
                            />
                        );
                    },
                },
                {
                    title: 'Markup',
                    key: 'markup',
                    align: 'right',
                    width: '10%',
                    render: (record: PriceOverride) => {
                        return <>{getHospitalFromHospitalId(hospitals, record.hospital_id)?.pricing_markup ?? 0} %</>;
                    },
                },
                {
                    title: 'Adj.',
                    key: 'adjustment',
                    align: 'right',
                    width: '10%',
                    render: (hospital_id: number, record: PriceOverride) => {
                        return (
                            <AdjustmentColumn
                                override_price_id={record.override_price_id}
                                override_serial_price_id={record.override_serial_price_id}
                                markup={record.markup!}
                                initialValue={record.adjustment}
                                isEditing={record.isEditing}
                                form={form}
                                updateAdjustment={updateAdjustment}
                            />
                        );
                    },
                },
                {
                    title: 'Price',
                    dataIndex: 'override_price_cents',
                    key: 'override_price_cents',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) => (a.override_price_cents ?? 0) - (b.override_price_cents ?? 0),
                    render: (override_price_cents: number, record: PriceOverride) => {
                        return (
                            <PriceColumn
                                override_price_id={record.override_price_id}
                                hospital_id={record.hospital_id}
                                initialValue={override_price_cents}
                                isEditing={record.isEditing}
                                isPricingRelative={isPricingRelative}
                                form={form}
                                updateOverridePrice={updateOverridePrice}
                                serial={false}
                            />
                        );
                    },
                },
                {
                    title: 'Serial Price Per Hour',
                    dataIndex: 'override_serial_price_cents',
                    key: 'override_serial_price_cents',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) =>
                        (a.override_serial_price_cents ?? 0) - (b.override_serial_price_cents ?? 0),
                    render: (override_serial_price_cents: number, record: PriceOverride) => {
                        return (
                            <PriceColumn
                                override_price_id={record.override_serial_price_id}
                                hospital_id={record.hospital_id}
                                initialValue={override_serial_price_cents ? override_serial_price_cents : undefined}
                                isEditing={record.isEditing}
                                isPricingRelative={isPricingRelative}
                                form={form}
                                updateOverridePrice={updateOverridePrice}
                                serial={true}
                            />
                        );
                    },
                },
                {
                    title: 'Serial Price Total',
                    dataIndex: 'override_serial_price_cents_total',
                    key: 'override_serial_price_cents_total',
                    align: 'right',
                    width: '20%',
                    sorter: (a: PriceOverride, b: PriceOverride) =>
                        (a.override_serial_price_cents ?? 0) - (b.override_serial_price_cents ?? 0),
                    render: (override_serial_price_cents: number, record: PriceOverride) => {
                        const basePriceCentsSerial = record.override_serial_price_cents;
                        const basePriceCentsSerialTotal = basePriceCentsSerial && serialHours ? basePriceCentsSerial * serialHours : 0;
                        return (
                            <Typography.Text disabled={!isSerial}>
                                {formatCurrency(roundTo(basePriceCentsSerialTotal / 100, 2))}
                            </Typography.Text>
                        );
                    },
                },
                {
                    title: '',
                    key: 'delete',
                    align: 'right',
                    width: '16px',
                    render: (record: PriceOverride) => {
                        return (
                            <Button
                                style={{ border: 0, height: 'auto', padding: '2px 4px', lineHeight: 1 }}
                                disabled={record.markup !== 0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removePriceOverride(record.override_price_id);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        );

                        return <></>;
                    },
                },
            ];
        }
        return [
            {
                title: 'Location Name',
                dataIndex: 'hospital_id',
                key: 'hospital_id',
                width: '40%',
                sorter: (a: PriceOverride, b: PriceOverride) => {
                    const aName = getHospitalFromHospitalId(hospitals, a.hospital_id)?.display_name ?? '';
                    const bName = getHospitalFromHospitalId(hospitals, b.hospital_id)?.display_name ?? '';
                    return aName.localeCompare(bName);
                },
                render: (hospital_id: number, record: PriceOverride) => {
                    return (
                        <HospitalColumn
                            formName={`location_${record.override_price_id}`}
                            initialValue={hospital_id}
                            hospitals={hospitals}
                            notSetHospitals={notSetHospitals}
                            onChange={(hospital_id) => {
                                updateHospitalId(record.override_price_id, hospital_id);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Markup',
                key: 'markup',
                align: 'right',
                width: '10%',
                render: (record: PriceOverride) => {
                    return <>{getHospitalFromHospitalId(hospitals, record.hospital_id)?.pricing_markup ?? 0} %</>;
                },
            },
            {
                title: 'Adj.',
                key: 'adjustment',
                align: 'right',
                width: '25%',
                render: (hospital_id: number, record: PriceOverride) => {
                    return (
                        <AdjustmentColumn
                            override_price_id={record.override_price_id}
                            markup={record.markup!}
                            initialValue={record.adjustment}
                            isEditing={record.isEditing}
                            form={form}
                            updateAdjustment={updateAdjustment}
                        />
                    );
                },
            },
            {
                title: 'Price',
                dataIndex: 'override_price_cents',
                key: 'override_price_cents',
                align: 'right',
                width: '25%',
                sorter: (a: PriceOverride, b: PriceOverride) => (a.override_price_cents ?? 0) - (b.override_price_cents ?? 0),
                render: (override_price_cents: number, record: PriceOverride) => {
                    return (
                        <PriceColumn
                            override_price_id={record.override_price_id}
                            hospital_id={record.hospital_id}
                            initialValue={override_price_cents}
                            isEditing={record.isEditing}
                            isPricingRelative={isPricingRelative}
                            form={form}
                            updateOverridePrice={updateOverridePrice}
                            serial={false}
                        />
                    );
                },
            },
            {
                title: '',
                key: 'delete',
                align: 'right',
                width: '16px',
                render: (record: PriceOverride) => {
                    return (
                        <Button
                            style={{ border: 0, height: 'auto', padding: '2px 4px', lineHeight: 1 }}
                            disabled={record.markup !== 0}
                            onClick={(e) => {
                                e.stopPropagation();
                                removePriceOverride(record.override_price_id);
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    );

                    return <></>;
                },
            },
        ];
    }, [hospitals, priceOverrides, isSerial, serialHours]);

    const newAbsolutePriceOverride: PriceOverride = {
        base_price_cents: undefined,

        base_price_id: undefined,
        hospital_id: undefined,
        id: catalogItemToUpdate?.id,
        name: undefined,
        override_price_cents: undefined,
        override_price_id: newRowId,
        isEditing: true,
        serial_hours: catalogItemToUpdate?.serial_hours,

        base_serial_price_cents: undefined,
        override_serial_price_cents: undefined,
        override_serial_price_id: newRowId - 1000,
    };

    const newRelativePriceOverride: PriceOverride = {
        base_price_cents: catalogItemToUpdate?.base_price_cents,
        base_price_id: undefined,
        hospital_id: undefined,
        id: catalogItemToUpdate?.id,
        name: undefined,
        override_price_cents: calculatePriceCentsFromMarkup(catalogItemToUpdate?.base_price_cents ?? 0, 0, 0),
        override_price_id: newRowId,
        isEditing: true,
        adjustment: 0,
        markup: 0,
        serial_hours: catalogItemToUpdate?.serial_hours,

        base_serial_price_cents: catalogItemToUpdate?.base_serial_price_cents ?? 0,
        override_serial_price_cents: calculatePriceCentsFromMarkup(catalogItemToUpdate?.base_serial_price_cents ?? 0, 0, 0),
        override_serial_price_id: newRowId - 1000,
    };

    const addPriceOverride = () => {
        const newPriceOverrides = isPricingRelative
            ? [...priceOverrides, newRelativePriceOverride]
            : [...priceOverrides, newAbsolutePriceOverride];
        setPriceOverrides(newPriceOverrides);
        setNewRowId(newRowId - 1);
    };

    const removePriceOverride = (override_price_id: number) => {
        const newPriceOverrides = priceOverrides.filter((item) => item.override_price_id !== override_price_id);
        setPriceOverrides(newPriceOverrides);
    };

    const updateHospitalId = (override_price_id: number, hospital_id: number) => {
        const newPriceOverrides = priceOverrides.map((item) => {
            if (item.override_price_id === override_price_id) {
                item.hospital_id = hospital_id;
                if (isPricingRelative) {
                    item.override_price_cents = calculatePriceCentsFromMarkup(
                        item.base_price_cents ?? 0,
                        item.markup ?? 0,
                        item.adjustment ?? 0,
                    );
                }
            }
            return item;
        });
        setPriceOverrides(newPriceOverrides);
    };

    const updateOverridePrice = (override_price_id: number, override_price_cents: number, withEnterKey?: boolean) => {
        let newPriceOverrides;
        if (isPricingRelative) {
            newPriceOverrides = priceOverrides.map((item) => {
                if (item.override_price_id === override_price_id) {
                    item.override_price_cents = override_price_cents;
                    if (item.base_price_cents !== undefined) {
                        const adjustment = roundTo(
                            (override_price_cents / item.base_price_cents - (1 + (item.markup ?? 0) / 100)) * 100,
                            2,
                        );
                        item.adjustment = adjustment;
                        item.override_serial_price_cents = calculatePriceCentsFromMarkup(
                            item.base_serial_price_cents ?? 0,
                            item.markup ?? 0,
                            adjustment,
                        );
                        form.setFieldValue(
                            'price_serial_' + item.override_serial_price_id,
                            formatCurrency(roundTo(item.override_serial_price_cents / 100, 2)).replace('$', ''),
                        );

                        form.setFieldValue('adjustment_' + item.override_price_id, adjustment);
                    }
                    if (withEnterKey) {
                        item.isEditing = false;
                    }
                } else if (item.override_serial_price_id === override_price_id) {
                    item.override_serial_price_cents = override_price_cents;
                    if (item.base_price_cents !== undefined) {
                        const adjustment = roundTo(
                            (override_price_cents / (item.base_serial_price_cents ?? 1) - (1 + (item.markup ?? 0) / 100)) * 100,
                            2,
                        );
                        item.adjustment = adjustment;
                        item.override_price_cents = calculatePriceCentsFromMarkup(item.base_price_cents ?? 0, item.markup ?? 0, adjustment);
                        form.setFieldValue('adjustment_' + item.override_price_id, adjustment);
                        form.setFieldValue(
                            'price_' + item.override_price_id,
                            formatCurrency(roundTo(item.override_price_cents / 100, 2)).replace('$', ''),
                        );
                    }
                    if (withEnterKey) {
                        item.isEditing = false;
                    }
                }
                return item;
            });
        } else {
            newPriceOverrides = priceOverrides.map((item) => {
                if (item.override_price_id === override_price_id) {
                    item.override_price_cents = override_price_cents;
                    if (withEnterKey) {
                        item.isEditing = false;
                    }
                } else if (item.override_serial_price_id === override_price_id) {
                    item.override_serial_price_cents = override_price_cents;
                    if (withEnterKey) {
                        item.isEditing = false;
                    }
                }
                return item;
            });
        }
        setPriceOverrides(newPriceOverrides);
    };

    const updateAdjustment = (override_price_id: number, adjustment: number, withEnterKey?: boolean, override_serial_price_id?: number) => {
        const newPriceOverrides = priceOverrides.map((item) => {
            if (item.override_price_id === override_price_id) {
                item.adjustment = adjustment;
                item.override_price_cents = calculatePriceCentsFromMarkup(item.base_price_cents ?? 0, item.markup ?? 0, adjustment);
                form.setFieldValue(
                    'price_' + item.override_price_id,
                    formatCurrency(roundTo(item.override_price_cents / 100, 2)).replace('$', ''),
                );
                if (withEnterKey) {
                    item.isEditing = false;
                }
            }
            if (override_serial_price_id && item.override_serial_price_id === override_serial_price_id) {
                item.adjustment = adjustment;
                item.override_serial_price_cents = calculatePriceCentsFromMarkup(
                    item.base_serial_price_cents ?? 0,
                    item.markup ?? 0,
                    adjustment,
                );
                form.setFieldValue(
                    'price_serial_' + item.override_serial_price_id,
                    formatCurrency(roundTo(item.override_serial_price_cents / 100, 2)).replace('$', ''),
                );
                if (withEnterKey) {
                    item.isEditing = false;
                }
            }
            return item;
        });
        setPriceOverrides(newPriceOverrides);
    };

    const toggleEditable = (override_price_id: number) => {
        const newPriceOverrides = priceOverrides.map((item) => {
            if (item.override_price_id === override_price_id) {
                return { ...item, isEditing: !item.isEditing };
            }
            return item;
        });
        setPriceOverrides(newPriceOverrides);
    };

    return (
        <>
            <Form.Item label='Name' className='no-margin'>
                {catalogItemName}
            </Form.Item>

            {dosingUnit !== undefined && (
                <Form.Item label='Dosing Unit' className='no-margin'>
                    {dosingUnit != null && dosingUnit.toString().replace('micro-', '')}
                </Form.Item>
            )}

            {calculatorType !== undefined && (
                <Form.Item label='Calculator Type' className='no-margin'>
                    {calculatorType}
                </Form.Item>
            )}

            {(typeId === 'M' || isExistingMedication) && (
                <>
                    <Form.Item
                        label='Pricing Unit'
                        name='pricing_unit'
                        initialValue={isExistingMedication ? catalogItemToUpdate.pricing_unit : null}
                        required={true}
                    >
                        <Select
                            // style={{ width: '14em' }}
                            options={[
                                { label: 'Same as Dosing Unit', value: null },
                                { label: 'Bag', value: 'bag' },
                                { label: 'Bottle', value: 'bottle' },
                                { label: 'Vial', value: 'vial' },
                            ]}
                            onChange={(value) => {
                                setPricingUnit(value);
                                if (!value) {
                                    form.setFieldValue('pricing_unit_size', null);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={capitalizeStr(pricingUnit + ' Size')}
                        name='pricing_unit_size'
                        hidden={!pricingUnit}
                        initialValue={isExistingMedication && pricingUnit ? catalogItemToUpdate.pricing_unit_size : null}
                        rules={[{ required: pricingUnit ? true : false }, { pattern: POSITIVE_INTEGER, message: 'Invalid value.' }]}
                    >
                        <Input suffix={isExistingMedication ? convertFromMicro(dosingUnit) : undefined} />
                    </Form.Item>
                </>
            )}

            {recurring !== undefined && (
                <Form.Item label='Is Recurring'>
                    <Radio.Group style={{ pointerEvents: 'none' }} value={recurring}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item
                label='Base Price'
                name='base_price_dollars'
                initialValue={
                    catalogItemToUpdate?.base_price_cents !== undefined ? roundTo(catalogItemToUpdate?.base_price_cents / 100, 2) : null
                }
                rules={[
                    {
                        required: true,
                        message: 'Base Price is required.',
                    },
                    {
                        pattern: FLOAT_REGEX,
                        message: 'Invalid value.',
                    },
                ]}
            >
                <Input
                    prefix='$'
                    ref={basePriceInputRef}
                    // style={{ width: '14em' }}
                    onChange={(e) => {
                        form.validateFields(['base_price_dollars']).then(() => {
                            updateRelativeOverrides();
                        });
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            basePriceInputRef.current?.blur();
                        }
                    }}
                />
            </Form.Item>

            {isSeriable && (
                <>
                    <Form.Item
                        label='Serial'
                        name='serial'
                        initialValue={isSerial}
                        rules={[
                            {
                                required: true,
                                message: 'Serial is required.',
                            },
                        ]}
                    >
                        <Radio.Group
                            value={isSerial}
                            onChange={(e) => {
                                setIsSerial(e.target.value);
                                form.validateFields(['base_price_dollars']).then(() => {
                                    if (isPricingRelative) {
                                        updateRelativeOverrides();
                                    } else {
                                        const changedToSerial = !catalogItemToUpdate?.serial && e.target.value;
                                        updateAbsoluteOverrides(changedToSerial);
                                    }
                                });
                            }}
                        >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label='Billing Increment'
                        name='serial_hours'
                        initialValue={catalogItemToUpdate?.serial_hours}
                        rules={[
                            {
                                required: isSerial,
                                message: 'Billing Increment is required if Serial is enabled',
                            },
                            {
                                pattern: POSITIVE_INTEGER,
                                message: 'Must be Positive Whole Number.',
                            },
                        ]}
                    >
                        <Input
                            suffix='hour(s)'
                            disabled={!isSerial}
                            // style={{ width: '14em' }}
                            onChange={(e) => {
                                form.validateFields(['base_price_dollars_serial']).then(() => {
                                    updateRelativeOverrides();
                                });
                                form.setFieldValue('serial_hours', e.target.value);
                                if (e.target.value && !isNaN(parseInt(e.target.value))) {
                                    setSerialHours(parseInt(e.target.value));
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Serial Price'
                        name='base_price_dollars_serial'
                        initialValue={
                            catalogItemToUpdate?.base_serial_price_cents !== undefined
                                ? roundTo(catalogItemToUpdate?.base_serial_price_cents / 100, 2)
                                : null
                        }
                        rules={[
                            {
                                required: isSerial,
                                message: 'Serial Price is required if Serial is enabled.',
                            },
                            {
                                pattern: FLOAT_REGEX,
                                message: 'Must be Positive Number',
                            },
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input
                            type='number'
                            disabled={!isSerial}
                            prefix='$'
                            suffix='per hour'
                            // style={{ width: '14em' }}
                            onChange={(e) => {
                                form.validateFields(['base_price_dollars_serial']).then(() => {
                                    updateRelativeOverrides();
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Serial Price Total'
                        dependencies={['base_price_dollars_serial', 'serial_hours']}
                        wrapperCol={{ span: 24 }}
                    >
                        {({ getFieldValue }) => {
                            const basePriceCentsSerial = getFieldValue('base_price_dollars_serial');
                            const basePriceCentsSerialTotal = basePriceCentsSerial && serialHours ? basePriceCentsSerial * serialHours : 0;
                            return <Typography.Text disabled={!isSerial}>{formatCurrency(basePriceCentsSerialTotal)}</Typography.Text>;
                        }}
                    </Form.Item>
                </>
            )}

            <Divider orientation='left' orientationMargin='0'>
                Location Pricing
            </Divider>

            <Form.Item label='Pricing Type' name='is_pricing_relative' initialValue={isPricingRelative}>
                <Radio.Group
                    onChange={(e) => {
                        setIsPricingRelative(e.target.value);
                    }}
                >
                    <Radio value={false}>Absolute</Radio>
                    <Radio value={true}>Relative</Radio>
                </Radio.Group>
            </Form.Item>
            {catalogItemToUpdate?.is_pricing_relative !== isPricingRelative && (
                <Alert
                    type='error'
                    showIcon
                    icon={<WarningOutlined />}
                    message={
                        isPricingRelative
                            ? 'Changing the pricing type to relative will reset all absolute overrides.'
                            : 'Changing the pricing type to absolute will reset all relative overrides.'
                    }
                    style={{ margin: '12px 0' }}
                />
            )}
            <Row style={{ fontSize: '12px', color: 'var(--gray-7)', textAlign: 'right' }}>
                <Col span={24}>
                    {priceOverrides?.length || 0} / {hospitals?.length || 0} Locations
                </Col>
            </Row>
            <Table
                columns={isPricingRelative ? relativePricingColumns : absolutePricingColumns}
                dataSource={priceOverrides}
                pagination={false}
                className='location-price_table'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            toggleEditable(record.override_price_id);
                        },
                        onDoubleClick: (event) => {},
                        onContextMenu: (event) => {},
                        onMouseEnter: (event) => {},
                        onMouseLeave: (event) => {},
                    };
                }}
            />

            <Row style={{ marginTop: '10px' }}>
                <Col span={24}>
                    <Button onClick={addPriceOverride} disabled={notSetHospitals?.length === 0}>
                        <PlusOutlined /> Add Location
                    </Button>
                </Col>
            </Row>
        </>
    );
};

interface PriceColumnProps {
    override_price_id?: number;
    hospital_id?: number;
    initialValue?: number;
    isEditing?: boolean;
    isPricingRelative: boolean;
    form: FormInstance;
    serial: boolean;
    updateOverridePrice: (override_price_id: number, override_price_cents: number, withEnterKey?: boolean) => void;
}

const PriceColumn: React.FC<PriceColumnProps> = ({
    override_price_id,
    hospital_id,
    initialValue,
    isEditing,
    isPricingRelative,
    form,
    updateOverridePrice,
    serial,
}) => {
    const inputRef = useRef<ElementRef<typeof Input>>(null);
    if (!isEditing && initialValue !== undefined) {
        return <span>{initialValue !== undefined ? formatCurrency(roundTo(initialValue / 100, 2)) : ''}</span>;
    }
    const name = serial ? 'price_serial_' + override_price_id + '' : 'price_' + override_price_id;
    return (
        <Form.Item
            style={{ margin: 0, padding: 0, textAlign: 'right' }}
            initialValue={initialValue !== undefined ? formatCurrency(roundTo(initialValue / 100, 2)).replace('$', '') : ''}
            name={name}
            rules={[
                {
                    required: true,
                    message: 'Price is required.',
                },
                {
                    pattern: FLOAT_REGEX,
                    message: 'Invalid value.',
                },
            ]}
        >
            <Input
                className='price-override-input'
                autoFocus={!isPricingRelative && initialValue !== undefined && initialValue !== null}
                prefix='$'
                ref={inputRef}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                defaultValue={initialValue !== undefined ? formatCurrency(roundTo(initialValue / 100, 2)).replace('$', '') : ''}
                onChange={(e) => {
                    if (!override_price_id) {
                        return;
                    }
                    form.validateFields([name]).then(() => {
                        const override_price_cents = Math.round(parseFloat(e.target.value) * 100);
                        updateOverridePrice(override_price_id, override_price_cents, false);
                    });
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        form.validateFields([name]).then(() => {
                            if (!override_price_id) {
                                return;
                            }
                            if (inputRef.current?.input?.value) {
                                const override_price_cents = Math.round(parseFloat(inputRef.current.input.value) * 100);
                                updateOverridePrice(override_price_id, override_price_cents, true);
                            }
                        });
                    }
                }}
            />
        </Form.Item>
    );
};

interface AdjustmentColumnProps {
    override_price_id: number;
    override_serial_price_id?: number;
    markup: number | undefined;
    initialValue?: number;
    isEditing?: boolean;
    form: FormInstance;
    updateAdjustment: (override_price_id: number, adjustment: number, withEnterKey?: boolean, override_serial_price_id?: number) => void;
}

const AdjustmentColumn: React.FC<AdjustmentColumnProps> = ({
    override_price_id,
    override_serial_price_id,
    markup,
    initialValue,
    isEditing,
    form,
    updateAdjustment,
}) => {
    const inputRef = useRef<ElementRef<typeof Input>>(null);
    if (!isEditing && initialValue !== undefined) {
        return <span>{initialValue + ' %'}</span>;
    }
    return (
        <Form.Item
            style={{ margin: 0, padding: 0, textAlign: 'right' }}
            initialValue={initialValue}
            name={'adjustment_' + override_price_id}
            rules={[
                {
                    required: true,
                    message: 'Required.',
                },
                {
                    pattern: SIGNED_FLOAT_REGEX,
                    message: 'Invalid value.',
                },
                {
                    validator: (_, value) => {
                        const adjustment = roundTo(parseFloat(value), 2);
                        if (adjustment + (markup ?? 0) < -100) {
                            return Promise.reject(new Error('Invalid value.'));
                        }
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <Input
                className='price-adjustment-input'
                autoFocus={markup !== undefined ? true : false}
                suffix='%'
                ref={inputRef}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                defaultValue={1}
                onChange={(e) => {
                    form.validateFields(['adjustment_' + override_price_id]).then(() => {
                        const adjustment = roundTo(parseFloat(e.target.value), 2);
                        updateAdjustment(override_price_id, adjustment, false, override_serial_price_id);
                    });
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        form.validateFields(['adjustment_' + override_price_id]).then(() => {
                            if (inputRef.current?.input?.value) {
                                const adjustment = roundTo(parseFloat(inputRef.current.input.value), 2);
                                updateAdjustment(override_price_id, adjustment, true, override_serial_price_id);
                            }
                        });
                    }
                }}
            />
        </Form.Item>
    );
};
