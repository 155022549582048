import { WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Form, FormInstance, Space, Spin, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useState } from 'react';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { roundTo } from 'utils/helpers/format';
import { calculatePriceCentsFromMarkup } from 'utils/helpers/general';
import { CatalogItemCreateOrUpdate, CatalogItemToUpdate, Hospital, PriceOverride, QueryResponse } from 'utils/types';
import './CreateOrUpdateCatalogModal.css';
import IncludeExcludeTab from './IncludeExcludeTab';
import { PricingTab } from './PricingTab';
import TaxCodeTab from './TaxCodeTab';

interface CreateOrUpdateCatalogProps {
    modalTitle: string;
    isOpen: boolean;
    catalogItemToUpdate: CatalogItemToUpdate | null;
    initialPriceOverrides: PriceOverride[];
    catalogItemTab: JSX.Element;
    handleCloseModal: () => void;
    handleSubmit: (values: CatalogItemCreateOrUpdate) => void;
    form: FormInstance;
    createCatalogItemLoading: boolean;
    updateCatalogItemLoading: boolean;
    initialPriceOverridesFetching: boolean;
    initialPriceOverridesError: boolean;
    typeId: string;
}

export const CreateOrUpdateCatalogModal = ({
    modalTitle,
    isOpen,
    catalogItemToUpdate,
    initialPriceOverrides,
    catalogItemTab,
    handleCloseModal,
    handleSubmit,
    form,
    createCatalogItemLoading,
    updateCatalogItemLoading,
    initialPriceOverridesFetching,
    initialPriceOverridesError,
    typeId,
}: CreateOrUpdateCatalogProps) => {
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});

    const [absolutePriceOverrides, setAbsolutePriceOverrides] = useState<PriceOverride[]>([]);
    const [relativePriceOverrides, setRelativePriceOverrides] = useState<PriceOverride[]>([]);
    const [isPricingRelative, setIsPricingRelative] = useState(false);
    const [newRowId, setNewRowId] = useState(-1);
    const [activeKey, setActiveKey] = useState('catalog_item');

    useEffect(() => {
        if (isOpen) {
            form.resetFields();
            setIsPricingRelative(catalogItemToUpdate?.is_pricing_relative ?? false);
            setAbsolutePriceOverrides(getAbsolutePriceOverrides());
            setRelativePriceOverrides(getRelativePriceOverrides());
        }
    }, [isOpen, initialPriceOverrides, catalogItemToUpdate]);

    const onFinish = (values: CatalogItemCreateOrUpdate) => {
        const valuesWithPricing = getValuesWithPricing(values);
        handleSubmit(valuesWithPricing);
    };

    const getAbsolutePriceOverrides = (changedToSerial: boolean = false) => {
        if (!catalogItemToUpdate || !initialPriceOverrides) {
            return [];
        }
        if (catalogItemToUpdate.is_pricing_relative) {
            return [];
        }
        return [...initialPriceOverrides]
            .map((priceOverrideAbsolute) => {
                const newSerialRowId = changedToSerial
                    ? -Math.abs(priceOverrideAbsolute.override_price_id) - 1000
                    : priceOverrideAbsolute.base_serial_price_id;
                return {
                    ...priceOverrideAbsolute,
                    override_serial_price_id: newSerialRowId,
                };
            })
            .sort((a, b) => {
                return (a.hospital_id ?? 0) - (b.hospital_id ?? 0);
            });
    };

    const getRelativePriceOverrides = () => {
        if (!initialPriceOverrides) {
            return [];
        }
        let rowId = newRowId;
        let data: PriceOverride[] = [];
        const basePriceCentsInForm = form.getFieldValue('base_price_dollars')
            ? Math.round((form.getFieldValue('base_price_dollars') ?? 0) * 100)
            : undefined;
        const baseSerialPriceCentsInForm = form.getFieldValue('base_price_dollars_serial')
            ? Math.round((form.getFieldValue('base_price_dollars_serial') ?? 0) * 100)
            : undefined;
        hospitals?.map((hospital) => {
            let adjustment;
            let overridePriceCents;
            let priceOverride;
            let overridePriceCentsSerial;
            const newRow = rowId--;
            const newSerialRow = newRow - 1000;

            if (!catalogItemToUpdate) {
                adjustment = 0;
                overridePriceCents = calculatePriceCentsFromMarkup(basePriceCentsInForm ?? 0, hospital.pricing_markup, adjustment);
                overridePriceCentsSerial = calculatePriceCentsFromMarkup(
                    baseSerialPriceCentsInForm ?? 0,
                    hospital.pricing_markup,
                    adjustment,
                );

                if (hospital.pricing_markup !== 0 || adjustment !== 0) {
                    data.push({
                        base_price_cents: basePriceCentsInForm ?? 0,
                        base_price_id: undefined,
                        hospital_id: parseInt(hospital.id),
                        id: undefined,
                        name: hospital.display_name,
                        override_price_cents: overridePriceCents,
                        override_price_id: newRow,
                        isEditing: false,
                        adjustment: roundTo(adjustment, 2),
                        markup: hospital.pricing_markup,
                        base_serial_price_cents: baseSerialPriceCentsInForm ?? 0,
                        override_serial_price_cents: overridePriceCentsSerial,
                        override_serial_price_id: newSerialRow,
                    });
                }
            } else {
                const currentBasePriceCents = basePriceCentsInForm ?? catalogItemToUpdate?.base_price_cents;
                const currentBaseSerialPriceCents = baseSerialPriceCentsInForm ?? catalogItemToUpdate?.base_serial_price_cents;
                if (catalogItemToUpdate.is_pricing_relative) {
                    priceOverride = initialPriceOverrides.find((item) => item.hospital_id?.toString() === hospital.id.toString());
                    if (priceOverride && priceOverride.override_price_cents !== undefined && priceOverride.base_price_cents !== undefined) {
                        const markupPriceCents = Math.round(priceOverride.base_price_cents * (hospital.pricing_markup / 100 + 1));
                        const differenceFromMarkupPriceCents = priceOverride.override_price_cents - markupPriceCents;
                        adjustment = roundTo((differenceFromMarkupPriceCents / priceOverride.base_price_cents) * 100, 4);
                        overridePriceCents = calculatePriceCentsFromMarkup(currentBasePriceCents, hospital.pricing_markup, adjustment);
                        overridePriceCentsSerial = calculatePriceCentsFromMarkup(
                            currentBaseSerialPriceCents ?? 0,
                            hospital.pricing_markup,
                            adjustment,
                        );
                    } else {
                        adjustment = 0;
                        overridePriceCents = calculatePriceCentsFromMarkup(currentBasePriceCents, hospital.pricing_markup, adjustment);
                        overridePriceCentsSerial = calculatePriceCentsFromMarkup(
                            currentBaseSerialPriceCents ?? 0,
                            hospital.pricing_markup,
                            adjustment,
                        );
                    }
                } else {
                    adjustment = 0;
                    overridePriceCents = calculatePriceCentsFromMarkup(currentBasePriceCents ?? 0, hospital.pricing_markup, adjustment);
                    overridePriceCentsSerial = calculatePriceCentsFromMarkup(
                        currentBaseSerialPriceCents ?? 0,
                        hospital.pricing_markup,
                        adjustment,
                    );
                }
                if (hospital.pricing_markup !== 0 || adjustment !== 0 || priceOverride) {
                    data.push({
                        base_price_cents: currentBasePriceCents,
                        base_price_id: undefined,
                        hospital_id: parseInt(hospital.id),
                        id: catalogItemToUpdate?.id,
                        name: hospital.display_name,
                        override_price_cents: overridePriceCents,
                        override_price_id: priceOverride?.override_price_id ?? newRow,
                        isEditing: false,
                        adjustment: roundTo(adjustment, 2),
                        markup: hospital.pricing_markup,
                        base_serial_price_cents: currentBaseSerialPriceCents ?? 0,
                        override_serial_price_cents: overridePriceCentsSerial,
                        override_serial_price_id: priceOverride?.override_serial_price_id ?? newSerialRow,
                    });
                }
            }
        });

        setNewRowId(rowId);

        return data.sort((a, b) => {
            if (a.markup! > 0 && b.markup === 0) {
                return -1;
            } else if (a.markup === 0 && b.markup! > 0) {
                return 1;
            }
            return (a.hospital_id ?? 0) - (b.hospital_id ?? 0);
        });
    };

    const updateRelativeOverrides = () => {
        setRelativePriceOverrides(getRelativePriceOverrides());
    };

    const updateAbsoluteOverrides = (changedToSerial: boolean) => {
        setAbsolutePriceOverrides(getAbsolutePriceOverrides(changedToSerial));
    };

    const getFinalPriceOverrides = (changedToSerial: boolean) => {
        const activePriceOverrides = isPricingRelative ? relativePriceOverrides : absolutePriceOverrides;
        const newAndChangedPriceOverrides = activePriceOverrides
            ?.filter((priceOverride) => {
                const initialPriceOverride = initialPriceOverrides?.find(
                    (initial) => initial.override_price_id === priceOverride.override_price_id,
                );
                if (
                    !initialPriceOverride ||
                    initialPriceOverride.override_price_cents !== priceOverride.override_price_cents ||
                    initialPriceOverride.override_serial_price_cents !== priceOverride.override_serial_price_cents
                ) {
                    return true;
                }
                if (changedToSerial) {
                    return true;
                }
                return false;
            })
            .map((item) => ({
                hospital_id: item.hospital_id,
                catalog_item_id: item.id,
                override_price_cents: item.override_price_cents,
                override_serial_price_cents: item.override_serial_price_cents,
            }));
        const deletedPriceOverrides = initialPriceOverrides
            ?.filter((initialPriceOverride) => {
                if (changedToSerial) {
                    return true;
                }
                return !activePriceOverrides?.some(
                    (priceOverride) => priceOverride.override_price_id === initialPriceOverride.override_price_id,
                );
            })
            .map((item) => ({
                override_price_id: item.override_price_id,
            }));

        return {
            newAndChangedPriceOverrides,
            deletedPriceOverrides,
        };
    };

    const getValuesWithPricing = (values: CatalogItemCreateOrUpdate) => {
        const previousBasePriceCents = catalogItemToUpdate?.base_price_cents;
        const base_price_cents = roundTo(parseFloat(values.base_price_dollars ?? '') * 100, 2);

        const previousBasePriceCentsSerial = catalogItemToUpdate?.base_serial_price_cents;
        const base_serial_price_cents = roundTo(parseFloat(values.base_price_dollars_serial ?? '') * 100, 2);
        const serial_hours = parseInt(`${values.serial_hours ?? ''}`);

        const { base_price_dollars, base_price_dollars_serial, ...restOfvalues } = values;
        const filteredValues = Object.keys(restOfvalues)
            .filter((key) => !key.startsWith('price_') && !key.startsWith('location_') && !key.startsWith('adjustment_'))
            .reduce((result: any, key: string) => {
                result[key] = values[key as keyof CatalogItemCreateOrUpdate];
                return result;
            }, {});

        const changedToSerial = catalogItemToUpdate?.serial === false && values.serial === true;
        const finalPriceOverrides = getFinalPriceOverrides(changedToSerial);

        return {
            ...filteredValues,
            base_price_cents: base_price_cents === previousBasePriceCents ? null : base_price_cents,
            base_serial_price_cents: base_serial_price_cents === previousBasePriceCentsSerial ? null : base_serial_price_cents,
            price_overrides: finalPriceOverrides.newAndChangedPriceOverrides,
            deleted_price_overrides: 'id' in filteredValues ? finalPriceOverrides.deletedPriceOverrides : undefined,
            serial_hours,
        };
    };

    return (
        <>
            <Drawer
                title={modalTitle}
                width={1200}
                open={isOpen}
                maskClosable={false}
                onClose={handleCloseModal}
                destroyOnClose
                className='create-or-update-catalog-item-modal'
                extra={
                    <Space>
                        <Button style={{ display: initialPriceOverridesError ? 'none' : undefined }} onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            loading={createCatalogItemLoading || updateCatalogItemLoading}
                            disabled={initialPriceOverridesFetching}
                            onClick={initialPriceOverridesError ? handleCloseModal : form.submit}
                            type='primary'
                        >
                            {initialPriceOverridesError ? 'Close' : 'Save'}
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={!!catalogItemToUpdate && initialPriceOverridesFetching}>
                    {!initialPriceOverridesError && (
                        <Form
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={() => {
                                form.validateFields().catch((error) => {
                                    const hasPricingErrors = error.errorFields.some(
                                        (item: any) =>
                                            item.name[0] === 'base_price_dollars' ||
                                            item.name[0].startsWith('price_') ||
                                            item.name[0].startsWith('location_') ||
                                            item.name[0].startsWith('adjustment_'),
                                    );
                                    const hasLocationErrors = error.errorFields.some((item: any) => item.name[0] === 'specific_locations');

                                    if (hasPricingErrors) {
                                        if (activeKey !== 'pricing') {
                                            setActiveKey('pricing');
                                        }
                                    } else if (hasLocationErrors) {
                                        if (activeKey !== 'include/exclude_list') {
                                            setActiveKey('include/exclude_list');
                                        }
                                    } else {
                                        setActiveKey('catalog_item');
                                    }
                                });
                            }}
                            labelCol={{ span: 9 }}
                        >
                            <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
                                {catalogItemTab}
                                <TabPane tab='Pricing' key='pricing' forceRender>
                                    <PricingTab
                                        catalogItemToUpdate={catalogItemToUpdate}
                                        priceOverrides={isPricingRelative ? relativePriceOverrides : absolutePriceOverrides}
                                        setPriceOverrides={isPricingRelative ? setRelativePriceOverrides : setAbsolutePriceOverrides}
                                        isPricingRelative={isPricingRelative}
                                        setIsPricingRelative={setIsPricingRelative}
                                        newRowId={newRowId}
                                        setNewRowId={setNewRowId}
                                        form={form}
                                        updateRelativeOverrides={updateRelativeOverrides}
                                        updateAbsoluteOverrides={updateAbsoluteOverrides}
                                        typeId={typeId}
                                    />
                                </TabPane>
                                <TabPane tab='Tax Code' key='tax_code' forceRender>
                                    <TaxCodeTab form={form} taxCode={catalogItemToUpdate?.avalara_tax_code} />
                                </TabPane>
                                {typeId !== 'S' && (
                                    <TabPane tab='Include/Exclude List' key='include/exclude_list' forceRender>
                                        <IncludeExcludeTab form={form} itemId={catalogItemToUpdate?.id} typeId={typeId} />
                                    </TabPane>
                                )}
                            </Tabs>
                        </Form>
                    )}
                    {initialPriceOverridesError && (
                        <Alert
                            type='error'
                            showIcon
                            icon={<WarningOutlined />}
                            message='An error has occurred while getting the price overrides.'
                            style={{ margin: '12px 0' }}
                        />
                    )}
                </Spin>
            </Drawer>
        </>
    );
};
