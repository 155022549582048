import { Checkbox, Divider, Form, Input, Select, Tooltip } from 'antd';
import React from 'react';
import { SIGNED_FLOAT_REGEX, TIMEZONES, US_STATES } from 'utils/consts';
import { roundTo } from 'utils/helpers/format';
import { Hospital, REGULAR_EXPRESSIONS } from 'utils/types';

interface DetailsTabPaneProps {
    hospital?: Hospital;
}

const DetailsTabPane: React.FC<DetailsTabPaneProps> = ({ hospital }) => {
    return (
        <>
            <Form.Item
                label='Hospital ID'
                name='hospital_id'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the hospital ID has been entered.',
                    },
                ]}
                initialValue={hospital?.id}
            >
                <Input disabled={!!hospital?.id} />
            </Form.Item>
            <Form.Item
                label='Company Code'
                name='company_code'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the company code has been entered.',
                    },
                ]}
                initialValue={hospital?.company_code}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Name'
                name='display_name'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the name has been entered.',
                    },
                ]}
                initialValue={hospital?.display_name}
            >
                <Input />
            </Form.Item>
            <Form.Item label='Street' name='street' rules={[{ required: true }]} initialValue={hospital?.street}>
                <Input />
            </Form.Item>
            <Form.Item label='City' name='city' rules={[{ required: true }]} initialValue={hospital?.city}>
                <Input />
            </Form.Item>
            <Form.Item label='State' name='state' rules={[{ required: true }]} initialValue={hospital?.state}>
                <Select>
                    {US_STATES.map((sta) => (
                        <Select.Option key={sta.abbreviation} value={sta.abbreviation}>
                            {sta.abbreviation}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label='Zip Code'
                name='zipcode'
                rules={[
                    {
                        pattern: RegExp(REGULAR_EXPRESSIONS.ZIP_CODE),
                        message: "Please ensure the hospital's zip code has the correct format.",
                    },
                    { required: true },
                ]}
                initialValue={hospital?.zipcode}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Timezone'
                name='timezone'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the timezone has been entered.',
                    },
                ]}
                initialValue={hospital?.timezone}
            >
                <Select>
                    {TIMEZONES.map((timezone: string) => (
                        <Select.Option key={timezone} value={timezone}>
                            {timezone}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label='Email'
                name='email'
                rules={[
                    {
                        type: 'email',
                        message: "Please ensure the hospital's email has the correct format.",
                    },
                    { required: true },
                ]}
                initialValue={hospital?.email}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Phone No.'
                name='phone_number'
                rules={[
                    {
                        pattern: RegExp(REGULAR_EXPRESSIONS.PHONE_NO),
                        message: "Please ensure the hospital's phone number has the correct format.",
                    },
                    { required: true },
                ]}
                initialValue={hospital?.phone_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Markup'
                name='pricing_markup'
                rules={[
                    {
                        required: true,
                        message: 'Markup is required.',
                    },
                    {
                        pattern: SIGNED_FLOAT_REGEX,
                        message: 'Invalid value.',
                    },
                    {
                        validator: (_, value) => {
                            const pricing_markup = roundTo(parseFloat(value), 2);
                            if (pricing_markup < -100) {
                                return Promise.reject(new Error('Invalid value.'));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
                initialValue={hospital?.pricing_markup}
            >
                <Input />
            </Form.Item>
            <Divider orientation='left' orientationMargin='0'>
                Advanced
            </Divider>
            <Tooltip title='To comply with state law, make customer birthdate required when sending home a controlled drug prescription.'>
                <Form.Item
                    name='require_customer_birthday'
                    valuePropName='checked'
                    labelAlign='right'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    initialValue={hospital?.require_customer_birthday ?? false}
                >
                    <Checkbox>Require customer birthdate for Controlled Drug Rx</Checkbox>
                </Form.Item>
            </Tooltip>
        </>
    );
};

export default DetailsTabPane;
