import { Form, message, Modal, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { rdvmEmailAndPhoneWarningConfig } from 'components/lib/ConfirmModalConfig';
import React, { useState } from 'react';
import { useGetUserDataQuery } from 'services/auth.service';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { useCheckRdvmEmailAndPhoneWarningMutation, useCreateRdvmMutation } from 'services/rdvm.service';
import { BASE_QUERY_OPTIONS } from 'utils/consts';
import { BaseModalProps, Hospital, QueryResponse, RdvmClinicCreation } from 'utils/types';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import AdvancedTabPane from './AdvancedTabPane';
import ClinicTabPane from './ClinicTabPane';
import './index.css';
import RelationshipTabPane from './RelationshipTabPane';

interface NewClinicmodalProps extends BaseModalProps {}

const initialState = {
    display_name: null,
    email: null,
    phone_number: null,
    fax_number: null,
    street: null,
    city: null,
    state: null,
    zipcode: null,
    note: null,
    website: null,
    status: 'Open',
    parent_organization: null,
    facility_type: null,
    emergency_care_offerings: null,
    hours_category: null,
    weekend_operations: null,
    associated_hospital_id: null,
    discount_type: null,
    discount_amount: null,
    discount_fixed: null,
    discount_percentage: null,
    discount_other: null,
    has_call_forwarding: null,
    relationship_status: null,
    priority: null,
    description: null,
};

const NewClinicModal: React.FC<NewClinicmodalProps> = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState('clinic');
    const [checkRdvmEmailAndPhoneWarning] = useCheckRdvmEmailAndPhoneWarningMutation();
    const [createRdvm] = useCreateRdvmMutation();
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const userHasPrimaryLocationEditPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.primary_location_edit,
    );

    const handleClose = () => {
        setIsOpen(false);
        form.resetFields();
    };

    const handleFormSubmit = () => {
        const errors = form.getFieldsError(['display_name', 'email', 'state', 'status']);
        const hasErrors = errors.some((field) => field.errors.length > 0);

        if (hasErrors) {
            setActiveTab('clinic');
            message.error('Please complete all required fields.');
        } else {
            form.submit();
        }
    };

    const onFinish = (values: RdvmClinicCreation) => {
        const onOk = () => {
            values.fax_number = values.fax_number ? values.fax_number : null;
            let has_call_forwarding = values.has_call_forwarding === 'true';

            let discount_fixed = null;
            let discount_percentage = null;
            let discount_other = null;
            const discount_amount = values.discount_amount;

            if (discount_amount) {
                if (values.discount_type === 'Percentage') {
                    discount_percentage = +discount_amount;
                } else if (values.discount_type === 'Other') {
                    discount_other = discount_amount;
                } else if (values.discount_type === 'Fixed amount') {
                    discount_fixed = +discount_amount;
                }
            }

            delete values.discount_amount;

            createRdvm({ ...values, has_call_forwarding, discount_fixed, discount_percentage, discount_other });
            handleClose();
        };
        checkRdvmEmailAndPhoneWarning({ body: values })
            .unwrap()
            .then((resp) => {
                if (resp.email.emit_warning || resp.phone.emit_warning) {
                    Modal.confirm(rdvmEmailAndPhoneWarningConfig(resp.email.emit_warning, resp.phone.emit_warning, onOk));
                } else {
                    onOk();
                }
            })
            .catch(() => {
                message.error('Something went wrong');
            });
    };

    return (
        <Modal title='New Clinic' open={isOpen} onOk={handleFormSubmit} okText='Save' onCancel={handleClose}>
            <Form
                form={form}
                onFinish={onFinish}
                layout='horizontal'
                labelAlign='left'
                labelCol={{ span: 7 }}
                labelWrap
                wrapperCol={{ span: 25 }}
                colon={false}
                className='clinic-modal'
                initialValues={initialState}
            >
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab='Clinic' key='clinic' forceRender>
                        <ClinicTabPane />
                    </TabPane>
                    <TabPane tab='Advanced' key='advanced' forceRender>
                        <AdvancedTabPane />
                    </TabPane>
                    <TabPane tab='Relationship' key='relationship' forceRender>
                        <RelationshipTabPane
                            hospitals={hospitals}
                            userHasPrimaryLocationEditPermission={userHasPrimaryLocationEditPermission}
                            form={form}
                        />
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};

export default NewClinicModal;
