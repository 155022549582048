import { MeetingNotesCreation, RdvmCheckEmailAndPhoneWarning, RdvmClinic, RdvmClinicCreation, UploadRdvmDraftResponseData } from 'utils/types';
import emptySplitApi, { RDVM_CLINIC } from './emptySplitApi';

export const rdvmApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getRdvms: builder.query<RdvmClinic[], void>({
            query: () => `rdvm_clinic`,
            transformResponse: (response: { data: RdvmClinic[] }) => {
                return response.data;
            },
            providesTags: [{ type: RDVM_CLINIC }],
        }),
        getRdvm: builder.query<RdvmClinic, string>({
            query: (id) => `rdvm_clinic/${id}`,
            transformResponse: (response: { data: RdvmClinic }) => {
                return response.data;
            },
            providesTags: (data, er, id) => {
                return [{ type: RDVM_CLINIC, id }];
            },
        }),
        checkRdvmEmailAndPhoneWarning: builder.mutation<RdvmCheckEmailAndPhoneWarning, { clinic_id?: string; body: RdvmClinicCreation }>({
            query: ({ clinic_id, body }) => {
                const { email, phone_number, ...rest } = body;
                return {
                    url: 'rdvm_clinic/check/email_and_phone_warning',
                    method: 'POST',
                    body: {
                        email,
                        phone_number,
                        clinic_id,
                    },
                };
            },
            transformResponse: (response: { data: RdvmCheckEmailAndPhoneWarning }) => {
                return response.data;
            },
        }),
        createRdvm: builder.mutation<void, RdvmClinicCreation>({
            query: (body) => {
                return {
                    url: `rdvm_clinic`,
                    method: 'POST',
                    body: {
                        ...body,
                        associated_hospital_id: body.associated_hospital_id ?? null,
                    },
                };
            },
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        updateRdvm: builder.mutation<
            void,
            {
                id: string;
                body: RdvmClinicCreation;
            }
        >({
            query: ({ id, body }) => {
                return {
                    url: `rdvm_clinic/${id}`,
                    method: 'PUT',
                    body: {
                        ...body,
                        associated_hospital_id: body.associated_hospital_id ?? null,
                    },
                };
            },
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        deactivateRdvmClinic: builder.mutation<void, { clinic_id: string; is_active: boolean }>({
            query: ({ clinic_id, is_active }) => ({
                url: `rdvm_clinic/${clinic_id}/deactivate`,
                method: 'PUT',
                body: { is_active },
            }),
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        mergeRdvmClinics: builder.mutation<void, { duplicate_rdvm_id: string | undefined; primary_rdvm_id: string | undefined }>({
            query: ({ duplicate_rdvm_id, primary_rdvm_id }) => ({
                url: `rdvm_clinic/${primary_rdvm_id}/merge`,
                method: 'PUT',
                body: {
                    duplicate_rdvm_id,
                    primary_rdvm_id,
                },
            }),
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        removeRdvmClinic: builder.mutation<void, { clinic_id: string | undefined; is_deleted: boolean }>({
            query: ({ clinic_id, is_deleted }) => ({
                url: `rdvm_clinic/${clinic_id}/remove`,
                method: 'PUT',
                body: {
                    is_deleted,
                },
            }),
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        uploadRdvmFile: builder.mutation<UploadRdvmDraftResponseData, FormData>({
            query: (formData) => ({
                url: 'rdvm_clinic/upload',
                method: 'POST',
                body: formData,
            }),
            transformResponse: (response: { data: UploadRdvmDraftResponseData }) => {
                return response.data;
            },
            invalidatesTags: [{ type: RDVM_CLINIC }],
        }),       
        createMeetingNote: builder.mutation<void, MeetingNotesCreation>({
            query: (body) => {
                return {
                    url: `rdvm_meeting`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
        updateMeetingNote: builder.mutation<
            void,
            {
                id: number;
                body: MeetingNotesCreation;
            }
        >({
            query: ({ id, body }) => {
                return {
                    url: `rdvm_meeting/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: () => [{ type: RDVM_CLINIC }],
        }),
    }),
});

export const {
    useGetRdvmsQuery,
    useGetRdvmQuery,
    useCheckRdvmEmailAndPhoneWarningMutation,
    useCreateRdvmMutation,
    useUpdateRdvmMutation,
    useDeactivateRdvmClinicMutation,
    useMergeRdvmClinicsMutation,
    useRemoveRdvmClinicMutation,
    useUploadRdvmFileMutation,
    useCreateMeetingNoteMutation,
    useUpdateMeetingNoteMutation,
} = rdvmApi;
