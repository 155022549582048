import { CreateDiagnostic, Diagnostic, UpdateDiagnostic } from 'services/diagnostics.service';
import { CreateFluid, Fluid, UpdateFluid } from 'services/fluids.service';
import { CreateMedication, Medication, UpdateMedication } from 'services/medicines.service';
import { CreateNonMedical, NonMedical, UpdateNonMedical } from 'services/nonMedical.service';
import { CreateSupplemental, Supplemental, UpdateSupplemental } from 'services/supplemental.service';
import { CreateTask, Task, UpdateTask } from 'services/tasks.service';
import { roundTo } from 'utils/helpers/format';
import { USER_PERMISSIONS } from 'utils/userPermissions';
import { EmployeeRoles, PackageInstructionType } from './enums';

export interface Clinic {
    id: string;
    name: string;
    email: string;
    phone_no: string;
    fax_no: string;
    street: string;
    city: string;
    state: string;
    zip_code: number | string;
    last_marketing_interaction_date: string;
    trailing_year_no_of_visits: number;
    trailing_year_revenue: number;
}

export interface Urn {
    id: string;
    name: string;
}

export interface HospitalCrematorium {
    name: string;
    street: string | null;
    city: string | null;
    state: string | null;
    zipcode: string | null;
    phone_number: string | null;
    fax_number: string | null;
    has_private_burial: boolean;
    urns: Urn[];
}

export interface Hospital {
    id: string;
    slug: string | null;
    display_name: string;
    phone_number: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    timezone: string;
    email?: string;
    stripe_account_id_last_6: string | null;
    stripe_public_key_last_6: string | null;
    pricing_markup: number;
    company_code: string;
    idexx_username: string | null;
    idexx_password: string | null;
    idexx_ivls_serial_number: string | null;
    webpacs_username: string | null;
    webpacs_password: string | null;
    webpacs_location_token: string | null;
    ring_central_extension_id: string | null;
    survey_code: string | null;
    patient_label_printer_id: string | null;
    prescription_label_printer_id: string | null;
    crematorium: HospitalCrematorium;
    require_customer_birthday: boolean;
}

export interface Cri {
    id: number;
    name: string;
    medication_id: number;
    fluids_id: number;
    fluids_volume_ml: number;
    medication_name: string;
    med_form: string;
    fluids_name: string;
    fluids_abbr: string;
}

export interface CriSearch {
    id: number;
    name: string;
    medication_id: number;
    medication_name: string;
}

// TODO this has not yet been set so we need to assess
// what this structure looks like
export interface HospitalMedicine extends Medication {
    quantity: number;
    unit_price: number;
    unit_cost: number;
}

export interface MedicineBrands {
    id: number;
    name: string;
}

export interface HospitalVisit {
    visit_id: string;
    hospital_id: string;
    owner_name: string;
    pet_name: string;
}

export interface VegUser {
    id: number;
    role_id: number | null;
    email: string;
    okta_subject: string | null;
    payroll_id: string | null;
    phone_number: string | null;
    first_name: string | null;
    last_name: string | null;
    hospital_bypass: boolean;
    is_active: boolean;
}

export interface VegRole {
    role_id: number;
    name: string;
}

export interface Employee {
    id: string;
    name: string;
    company: string;
    email: string;
    phone: string;
    role: EmployeeRoles | null;
}

interface BaseSearchInstruction {
    id: number;
    name: string;
}

export interface MedicineSearchInstruction extends BaseSearchInstruction {
    type_id: PackageInstructionType.MEDICATION;
    form: string;
    brand_name: string;
    dose_unit: string;
    numerator_value: number;
    numerator_unit: string;
    denominator_value: number;
    denominator_unit: string;
    calculator_type: string;
    default_dose: number | null;
    default_dose_unit: string | null;
    default_route: string | null;
    default_frequency: string | null;
    default_duration_mins: number | null;
}

interface DiagnosticSearchInstruction extends BaseSearchInstruction {
    type_id: PackageInstructionType.DIAGNOSTIC;
    default_frequency: string | null;
    default_duration_mins: number | null;
}

interface TaskSearchInstruction extends BaseSearchInstruction {
    type_id: PackageInstructionType.TASK;
    default_frequency: string | null;
    default_duration_mins: number | null;
}

interface APIFluids {
    default_rate_mcl_per_hr: number | null; //Rate of FLUID, sent to database
}

interface FrontendFluids {
    default_rate_ml_per_hr: number | null; //Rate of FLUID infusion, set on pump
}

interface APIFluidSearchInstruction extends BaseSearchInstruction, APIFluids {
    type_id: PackageInstructionType.FLUID;
    default_duration_mins: number | null;
    default_volume_ml: number | null;
}

interface FluidSearchInstruction extends BaseSearchInstruction, FrontendFluids {
    type_id: PackageInstructionType.FLUID;
    default_duration_mins: number | null;
    default_volume_ml: number | null;
}

export const isInstanceOfAPIFluidSearchInstruction = (item: any): item is APIFluidSearchInstruction =>
    (item as APIFluidSearchInstruction).default_rate_mcl_per_hr !== undefined;

export const backendFluidToFrontendFluid = (fluid: APIFluidSearchInstruction): FluidSearchInstruction => {
    const { default_rate_mcl_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        default_rate_ml_per_hr: default_rate_mcl_per_hr ? roundTo(default_rate_mcl_per_hr / 1000, 2) : null,
    };
};

interface APICri {
    default_rate_mcl_per_hr: number | null; //Rate of FLUID, sent to database
}

interface FrontendCri {
    default_rate_ml_per_hr: number | null; //Rate of FLUID infusion, set on pump
}

interface APICriSearchInstruction extends BaseSearchInstruction, APICri {
    type_id: PackageInstructionType.CRI;
    default_dose: number | null;
    fluids_volume_ml: number | null;
    fluids_id: number | null;
    default_duration_mins: number | null;
}

interface CriSearchInstruction extends BaseSearchInstruction, FrontendCri {
    type_id: PackageInstructionType.CRI;
    default_dose: number | null;
    fluids_volume_ml: number | null;
    fluids_id: number | null;
    default_duration_mins: number | null;
}

export const isInstanceOfAPICriSearchInstruction = (item: any): item is APICriSearchInstruction =>
    (item as APICriSearchInstruction).default_rate_mcl_per_hr !== undefined;

export const backendCriToFrontendCri = (fluid: APICriSearchInstruction): CriSearchInstruction => {
    const { default_rate_mcl_per_hr, ...restofCri } = fluid;
    return {
        ...restofCri,
        default_rate_ml_per_hr: default_rate_mcl_per_hr ? roundTo(default_rate_mcl_per_hr / 1000, 2) : null,
    };
};

export interface PackageSearchInstruction extends BaseSearchInstruction {
    type_id: PackageInstructionType.PACKAGE;
}
export const isInstanceOfPackageSearchInstruction = (item: any): item is PackageSearchInstruction =>
    (item as PackageSearchInstruction).type_id === PackageInstructionType.PACKAGE;

export type Instruction =
    | MedicineSearchInstruction
    | DiagnosticSearchInstruction
    | TaskSearchInstruction
    | FluidSearchInstruction
    | CriSearchInstruction
    | PackageSearchInstruction;

export interface PackageInstructionBase {
    id: string;
    package_id: string;
    frequency: number;
    start_delay_mins: number;
    duration_mins: number;
    toggled: boolean;
    name: string;
}
export interface PackageMedicationInstruction extends PackageInstructionBase {
    type_id: PackageInstructionType.MEDICATION;
    medication_id: string;
    route_id: string;
}

export interface PackageFluidsInstruction extends PackageInstructionBase {
    type_id: PackageInstructionType.FLUID;
    fluids_id: string;
    route_id: string;
    fluids_volume_ml?: number;
    rate_mcl_per_hr?: number;
}

export interface PackageDiagnosticInstruction extends PackageInstructionBase {
    type_id: PackageInstructionType.DIAGNOSTIC;
    diagnostic_id: string;
}

export interface PackageTaskInstruction extends PackageInstructionBase {
    type_id: PackageInstructionType.TASK;
    task_id: number;
    name: string;
}

export interface PackageNonMedicalInstruction extends PackageInstructionBase {
    type_id: PackageInstructionType.NON_MEDICAL;
    non_medical_id: number;
    name: string;
    quantity?: number;
}

export type PackageInstruction =
    | PackageFluidsInstruction
    | PackageMedicationInstruction
    | PackageDiagnosticInstruction
    | PackageTaskInstruction
    | PackageNonMedicalInstruction;

export interface Package {
    id: string;
    name: string;
    author: string;
    instructions: PackageInstruction[];
}

export interface Differential {
    id: string;
    name: string;
}

export interface Complaint {
    id: string;
    common_name: string;
    medical_name: string | null;
    differentials: Differential[];
}

export interface Fluids {
    id: number;
    name: string;
    abbreviation: string;
}

export interface NoteMacro {
    id: number;
    label: string;
    content: string;
    description: string;
    key_word: string;
}

export interface PimsUser {
    user_id: number;
    email: string;
    first_name: string;
    last_name: string;
    last_login_at: number;
    hospital_id: number;
    hospital_name: string;
    allowed_hospitals: { [key: string]: string };
    user_permissions: USER_PERMISSIONS[];
}

export interface MeetingNotes {
    id: number;
    clinic_id: string;
    mtg_type: string;
    mtg_date: number;
    note: string;
    lead_marketer: null;
    created_by: number;
    created_at: number;
    user_first_name: string;
    user_last_name: string;
}

export interface RdvmClinic {
    section_group: number;
    clinic_id: string;
    display_name: string;
    note: string;
    email: string;
    phone_number: string;
    fax_number: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    last_interaction: number;
    associated_hospital_id?: number;
    associated_hospital_location?: string;
    total_items?: number;
    past_six_months_revenue?: number;
    description?: string;
    parent_organization?: string;
    facility_type?: string;
    emergency_care_offerings?: string;
    weekend_operations?: string;
    hours_category?: string;
    website?: string;
    status: string;
    discount_type?: string;
    discount_fixed?: number;
    discount_percentage?: number;
    discount_other?: string;
    has_call_forwarding?: boolean;
    relationship_status?: string;
    priority?: string;
    meetings: MeetingNotes[];
    stage: string;
    is_active: boolean;
}

export interface MeetingNotesCreation {
    clinic_id: string;
    mtg_type: string;
    mtg_date: number;
    note: string;
}

export interface RdvmCheckEmailAndPhoneWarning {
    email: { emit_warning: boolean };
    phone: { emit_warning: boolean };
}

export interface RdvmClinicCreation {
    display_name: string;
    email: string;
    phone_number: string | null;
    fax_number: string | null;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    note?: string | null;
    website: string;
    status: string;
    parent_organization: string;
    facility_type: string;
    emergency_care_offerings: string;
    hours_category: string;
    weekend_operations: string;
    associated_hospital_id?: number;
    discount_type: 'Other' | 'None' | 'Percentage' | 'Fixed amount';
    discount_amount?: string;
    discount_fixed: number | null;
    discount_percentage: number | null;
    discount_other: string | null;
    has_call_forwarding: 'true' | 'false' | boolean;
    relationship_status: string;
    priority: string;
}

export interface PriceOverride {
    base_price_cents?: number;
    base_price_id?: number;
    hospital_id?: number;
    id?: number;
    name?: string;
    override_price_cents?: number;
    override_price_id: number;
    isEditing?: boolean;
    adjustment?: number;
    markup?: number;
    base_serial_price_id?: number;
    base_serial_price_cents?: number;
    override_serial_price_cents?: number;
    override_serial_price_id?: number;
    serial_hours?: number;
}

export type CatalogItemToUpdate = Medication | Task | NonMedical | Fluid | Supplemental | Diagnostic;

export type CatalogItemCreateOrUpdate =
    | CreateMedication
    | UpdateMedication
    | CreateTask
    | UpdateTask
    | CreateNonMedical
    | UpdateNonMedical
    | CreateFluid
    | UpdateFluid
    | CreateSupplemental
    | UpdateSupplemental
    | CreateDiagnostic
    | UpdateDiagnostic;

export interface UploadRdvmDraftResponseData {
    success: Array<{
        clinic: string;
    }>;
    failure: Array<{
        clinic: string;
        error: string;
    }>;
}
