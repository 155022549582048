import { RdvmClinic } from 'utils/types';
import { pimsApi } from './pimsApi';

interface AccountsReceivableInvoicesParams {
    report_date: string;
}

interface UncollectibleInvoicesParams {
    report_start_date: string;
    report_end_date: string;
}

interface PaymentsOnUncollectibleParams {
    report_start_date: string;
    report_end_date: string;
}

export const downloadFile = async (response: any, fileName: string) => {
    const url = window.URL.createObjectURL(await response.blob());
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
};

export const reportsApi = pimsApi.injectEndpoints({
    endpoints: (builder) => ({
        accountsReceivableInvoices: builder.query<null, AccountsReceivableInvoicesParams>({
            query: ({ report_date }) => ({
                url: `report/accounts_receivable_invoices/${report_date}`,
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.ok) {
                        const fileName = `accounts_receivable_invoices_${report_date}.csv`;
                        await downloadFile(response, fileName);
                    }
                },
            }),
        }),
        uncollectibleInvoices: builder.query<null, UncollectibleInvoicesParams>({
            query: ({ report_start_date, report_end_date }) => ({
                url: `report/uncollectible_invoices/${report_start_date}/${report_end_date}`,
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.ok) {
                        const fileName = `uncollectible_invoices_${report_start_date}-${report_end_date}.csv`;
                        await downloadFile(response, fileName);
                    }
                },
            }),
        }),
        paymentsOnUncollectible: builder.query<null, PaymentsOnUncollectibleParams>({
            query: ({ report_start_date, report_end_date }) => ({
                url: `report/payments_on_uncollectible/${report_start_date}/${report_end_date}`,
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.ok) {
                        const fileName = `payments_on_uncollectible_${report_start_date}-${report_end_date}.csv`;
                        await downloadFile(response, fileName);
                    }
                },
            }),
        }),
        revenueDetail: builder.query<null, { month: number; year: number }>({
            query: ({ month, year }) => ({
                url: `report/revenue_detail?month=${month}&year=${year}`,
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.ok) {
                        const fileName = `revenue_detail_${year}_${month}.csv`;
                        await downloadFile(response, fileName);
                    }
                },
            }),
        }),
        allLocationsPayments: builder.query<null, { year: number }>({
            query: ({ year }) => ({
                url: `report/accounting/${year}/all`,
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.ok) {
                        const fileName = `all_location_payments_${year}.csv`;
                        await downloadFile(response, fileName);
                    }
                },
            }),
        }),
        visitCountForRdvm: builder.query<number, { rdvm_id: string | undefined }>({
            query: ({ rdvm_id }) => ({
                url: `rdvm/${rdvm_id}/visits/count`,
                method: 'GET',
            }),
            transformResponse: (response: { data: { count: number } }) => {
                return response.data.count;
            },
        }),
        searchRdvm: builder.query<RdvmClinic[], number | null>({
            query: (visitId) => {
                return `rdvm/search`;
            },
            transformResponse: (response: { data: RdvmClinic[] }) => response.data,
        }),
    }),
});

export const {
    useLazyAccountsReceivableInvoicesQuery,
    useLazyUncollectibleInvoicesQuery,
    useLazyPaymentsOnUncollectibleQuery,
    useLazyRevenueDetailQuery,
    useLazyAllLocationsPaymentsQuery,
    useVisitCountForRdvmQuery,
    useSearchRdvmQuery,
} = reportsApi;
