import 'antd/dist/antd.css';
import {
    ClinicDetail,
    ClinicsListing,
    ComplaintsDetail,
    DCsListing,
    DiagnosticsListing,
    HospitalDetail,
    HospitalsListing,
    Login,
    MedicationsListing,
    Notation,
    PackageDetail,
    PackagesListing,
    TasksListing,
    UsersListing,
} from 'components/views';
import useIsUserAuthenticated from 'hooks/useIsUserAuthenticated';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'styles/index.css';
import { ROUTES } from 'utils/types';
import ProtectedRoute from './HOCs/ProtectedRoute';
import UnprotectedRoute from './HOCs/UnprotectedRoute';
import FluidsListing from './views/FluidsListing';
import NonMedicalListing from './views/NonMedicalListing';
import { Reports } from './views/Reports';
import { SSOPage } from './views/SSO/SSO';
import SupplementalListing from './views/SupplementalListing';

const App: React.FC = () => {
    let [isUserAuthenticated] = useIsUserAuthenticated();

    return (
        <Router>
            <Switch>
                <UnprotectedRoute
                    path={ROUTES.SSO}
                    component={SSOPage}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <UnprotectedRoute
                    path={ROUTES.LOGIN}
                    component={Login}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    exact
                    path={ROUTES.HOSPITALS_LISTING}
                    component={HospitalsListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.HOSPITAL_DETAIL}
                    component={HospitalDetail}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.MEDICATIONS_LISTING}
                    component={MedicationsListing}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.USER_LISTING}
                    component={UsersListing}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.CLINICS_LISTING}
                    component={ClinicsListing}
                    exact
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.CLINIC_DETAIL}
                    component={ClinicDetail}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.DIAGNOSTICS_LISTING}
                    component={DiagnosticsListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.TASKS_LISTING}
                    exact
                    component={TasksListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.FLUIDS_LISTING}
                    exact
                    component={FluidsListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.SUPPLEMENTALS_LISTING}
                    exact
                    component={SupplementalListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.NON_MEDICAL_LISTING}
                    exact
                    component={NonMedicalListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.PACKAGES_LISTING}
                    exact
                    component={PackagesListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.PACKAGE_DETAIL}
                    exact
                    component={PackageDetail}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.COMPLAINTS_AND_DIFFERENTIALS}
                    exact
                    component={DCsListing}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.COMPLAINTS_DETAIL}
                    exact
                    component={ComplaintsDetail}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.NOTATION}
                    exact
                    component={Notation}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
                <ProtectedRoute
                    path={ROUTES.REPORTS}
                    exact
                    component={Reports}
                    isUserLoggedIn={isUserAuthenticated.loggedIn}
                    isUserFetching={isUserAuthenticated.isFetching}
                />
            </Switch>
        </Router>
    );
};

export default App;
