export enum ROUTES {
    USER_LISTING = '/management/users',
    USER_PERMISSIONS = '/management/user/permissions',
    HOSPITALS_LISTING = '/',
    HOSPITAL_DETAIL = '/management/hospitals/:id',
    MEDICATIONS_LISTING = '/management/medications',
    CLINICS_LISTING = '/marketing/clinics',
    CLINIC_DETAIL = '/marketing/clinics/:id',
    DIAGNOSTICS_LISTING = '/management/diagnostics',
    TASKS_LISTING = '/management/tasks',
    FLUIDS_LISTING = '/management/fluids',
    SUPPLEMENTALS_LISTING = '/management/supplementals',
    NON_MEDICAL_LISTING = '/management/non_medical',
    PACKAGES_LISTING = '/management/packages',
    PACKAGE_DETAIL = '/management/packages/:id',
    COMPLAINTS_AND_DIFFERENTIALS = '/management/differentials+complaints',
    COMPLAINTS_DETAIL = '/management/complaints/:id',
    NOTATION = '/management/notation',
    LOGIN = '/private/login',
    SSO = '/login',
    REPORTS = '/reports',
}

export enum REGULAR_EXPRESSIONS {
    PHONE_NO = '^[0-9]{10}$',
    ZIP_CODE = '^[0-9]{5}$',
    FREQUENCY = '^q([1-9][0-9]*)(?:h|hr|hour)$|(^q([1-9][0-9]*)d$)|once|qqh|qid|tid|bid|qd|sid|qod/g',
}

export namespace ROUTES {
    export const addID = (route: string, id: string) => {
        return route.replace(':id', id);
    };
}

export enum EmployeeRoles {
    OTHER = 'Other',
    MANAGER = 'Manager',
    NURSE = 'Nurse',
    DOCTOR = 'Doctor',
}

export enum MedicineCalculationTypes {
    SIMPLE = 'simple',
    COMPLEX = 'complex',
}

export enum ActionResultWidget {
    NUMERIC = 1,
    SMALL_TEXT = 2,
    BIG_TEXT = 3,
    BOOLEAN = 4,
    MUTLI_CHOICE = 5,
}

export enum PackageInstructionType {
    MEDICATION = 'M',
    FLUID = 'F',
    DIAGNOSTIC = 'D',
    TASK = 'T',
    CRI = 'C',
    NON_MEDICAL = 'N',
    PACKAGE = 'P',
    OXYGEN_THERAPY = 'OT',
}

export enum MEDICINE_FIELDS {
    NAME = 'name',
    FORM = 'form',
    GENERIC_NAME = 'generic_name',
    BRAND_NAME = 'brand_name',
    DOSING_UNIT = 'dosing_unit',
    NUMERATOR_VALUE = 'numerator_value',
    NUMERATOR_UNIT = 'numerator_unit',
    DENOMINATOR_VALUE = 'denominator_value',
    DENOMINATOR_UNIT = 'denominator_unit',
    CALCULATOR_TYPE = 'calculator_type',
    DEFAULT_DOSE = 'default_dose',
    DEFAULT_DOSE_UNIT = 'default_dose_unit',
    DEFAULT_DURATION_MINS = 'default_duration_mins',
    DEFAULT_FREQUENCY = 'default_frequency',
    DEFAULT_ROUTE = 'default_route',
}
