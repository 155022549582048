import { Form, message, Modal, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateOrEditHospitalParams, useCreateHospitalMutation, useEditHospitalMutation } from 'services/hospitals.service';
import { roundTo } from 'utils/helpers/format';
import { BaseModalProps, Hospital, ROUTES } from 'utils/types';
import CrematoriumTabPane from './CrematoriumTabPane';
import DetailsTabPane from './DetailsTabPane';
import IntegrationsTabPane from './IntegrationsTabPane';

interface ManageHospitalModalProps extends BaseModalProps {
    hospital?: Hospital;
}

const ManageHospitalModal: React.FC<ManageHospitalModalProps> = ({ isOpen, setIsOpen, hospital }) => {
    const [activeKey, setActiveKey] = useState('details');
    const [resetStripeAccountId, setResetStripeAccountId] = useState(false);
    const [resetStripePublicKey, setResetStripePublicKey] = useState(false);

    const [form] = Form.useForm<CreateOrEditHospitalParams>();
    const history = useHistory();

    const [createHospital, { isLoading: loadingCreateHospital }] = useCreateHospitalMutation();
    const [editHospital, { isLoading: loadingEditHospital }] = useEditHospitalMutation();

    const handleClose = () => {
        setIsOpen(false);
        setResetStripeAccountId(false);
        setResetStripePublicKey(false);
    };

    const handleSave = (values: CreateOrEditHospitalParams) => {
        if (!!hospital) {
            const hasSavedStripeDetails = hospital.stripe_account_id_last_6 !== null && hospital.stripe_public_key_last_6 !== null;
            const stripe_account_id = !hasSavedStripeDetails || resetStripeAccountId ? values.stripe_account_id : null;
            const stripe_public_key = !hasSavedStripeDetails || resetStripePublicKey ? values.stripe_public_key : null;

            editHospital({
                ...values,
                stripe_account_id: stripe_account_id,
                stripe_public_key: stripe_public_key,
                pricing_markup: roundTo(values.pricing_markup, 2) * 100,
                crematorium: { ...values.crematorium, urns: values.crematorium.urns ?? [] },
            })
                .unwrap()
                .then(() => {
                    message.success('Hospital updated successfully.');
                    handleClose();
                })
                .catch(() => {
                    message.error(
                        `It seems there has been an issue adding the '${form.getFieldValue('display_name')}' hospital, please try again.`,
                    );
                });
        } else {
            createHospital({
                ...values,
                hospital_id: +values.hospital_id,
                stripe_account_id: values.stripe_account_id || null,
                stripe_public_key: values.stripe_public_key || null,
                pricing_markup: roundTo(values.pricing_markup, 2) * 100,
                crematorium: { ...values.crematorium, urns: values.crematorium.urns ?? [] },
            })
                .unwrap()
                .then((resp) => {
                    history.push(ROUTES.addID(ROUTES.HOSPITAL_DETAIL, resp.id));
                })
                .catch(() => {
                    message.error(
                        `It seems there has been an issue adding the '${form.getFieldValue('display_name')}' hospital, please try again.`,
                    );
                });
        }
    };

    const handleFail = () => {
        const hasErrorOnCrematorium = form
            .getFieldsError()
            .filter((field) => field.name[0] === 'crematorium')
            .some((field) => field.errors.length);

        if (hasErrorOnCrematorium) {
            if (activeKey !== 'crematorium') {
                setActiveKey('crematorium');
            }
        }
    };

    return (
        <Modal
            className='hospital-modal'
            confirmLoading={loadingCreateHospital || loadingEditHospital}
            open={isOpen}
            okText='Save'
            title={`${hospital ? 'Edit' : 'Add'} Hospital`}
            onOk={form.submit}
            onCancel={handleClose}
            destroyOnClose
        >
            <Form
                form={form}
                labelCol={{ span: 7 }}
                preserve={false}
                wrapperCol={{ span: 17 }}
                onFinish={handleSave}
                onFinishFailed={handleFail}
            >
                <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
                    <TabPane tab='Details' key='details' forceRender>
                        <DetailsTabPane hospital={hospital} />
                    </TabPane>

                    <TabPane tab='Integrations' key='integrations' forceRender>
                        <IntegrationsTabPane
                            form={form}
                            hospital={hospital}
                            resetStripeAccountId={resetStripeAccountId}
                            resetStripePublicKey={resetStripePublicKey}
                            setResetStripeAccountId={setResetStripeAccountId}
                            setResetStripePublicKey={setResetStripePublicKey}
                        />
                    </TabPane>

                    <TabPane tab='Crematorium' key='crematorium' forceRender>
                        <CrematoriumTabPane form={form} crematorium={hospital?.crematorium} />
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};

export default ManageHospitalModal;
