import { Form, Input, Select } from 'antd';
import { RDVM_EMERGENCY_CARE_OFFERINGS, RDVM_FACILITY, RDVM_HOURS, RDVM_WEEKEND_OPERATIONS } from 'utils/consts';

const AdvancedTabPane = () => (
    <>
        <Form.Item name='parent_organization' label='Parent Organization:'>
            <Input />
        </Form.Item>
        <Form.Item name='facility_type' label='Facility Type:'>
            <Select placeholder='Select facility type'>
                {RDVM_FACILITY.map((facility) => (
                    <Select.Option key={facility.value} value={facility.value}>
                        {facility.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item name='emergency_care_offerings' label='Emergency Care Offerings:'>
            <Select placeholder='Select emergency care offering'>
                {RDVM_EMERGENCY_CARE_OFFERINGS.map((emc) => (
                    <Select.Option key={emc.value} value={emc.value}>
                        {emc.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item name='hours_category' label='Hours:'>
            <Select placeholder='Select hours'>
                {RDVM_HOURS.map((hours) => (
                    <Select.Option key={hours.value} value={hours.value}>
                        {hours.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item name='weekend_operations' label='Weekend Operations:'>
            <Select placeholder='Select weekend operations'>
                {RDVM_WEEKEND_OPERATIONS.map((weekend_operation) => (
                    <Select.Option key={weekend_operation.value} value={weekend_operation.value}>
                        {weekend_operation.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    </>
);

export default AdvancedTabPane;
