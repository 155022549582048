import { Button, Divider, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { CreateOrEditHospitalParams } from 'services/hospitals.service';
import { Hospital } from 'utils/types';

interface IntegrationsTabPaneProps {
    form: FormInstance<CreateOrEditHospitalParams>;
    hospital?: Hospital;
    resetStripeAccountId: boolean;
    setResetStripeAccountId: (check: boolean) => void;
    resetStripePublicKey: boolean;
    setResetStripePublicKey: (check: boolean) => void;
}

const IntegrationsTabPane: React.FC<IntegrationsTabPaneProps> = ({
    form,
    resetStripeAccountId,
    resetStripePublicKey,
    setResetStripeAccountId,
    setResetStripePublicKey,
    hospital,
}) => {
    const [stripeAccountIdDisplay, setStripeAccountIdDisplay] = useState(formatStripeValue(hospital?.stripe_account_id_last_6 || null));
    const [stripePublicKeyDisplay, setStripePublicKeyDisplay] = useState(formatStripeValue(hospital?.stripe_public_key_last_6 || null));
    const hasSavedStripeDetails = !!hospital?.stripe_account_id_last_6 && !!hospital?.stripe_public_key_last_6;

    function formatStripeValue(value: string | null) {
        return value ? '...' + value : null;
    }

    const resetStripeDisplayValues = () => {
        setStripeAccountIdDisplay(formatStripeValue(hospital?.stripe_account_id_last_6 || null));
        setStripePublicKeyDisplay(formatStripeValue(hospital?.stripe_public_key_last_6 || null));
    };

    useEffect(() => {
        setResetStripeAccountId(false);
        setResetStripePublicKey(false);
        resetStripeDisplayValues();
    }, [hospital]);

    return (
        <>
            <Divider orientation='left' orientationMargin='0'>
                Cubex
            </Divider>

            <Form.Item
                label='Cubex Slug'
                name='slug'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the Cubex slug has been entered.',
                    },
                ]}
                initialValue={hospital?.slug}
            >
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Idexx
            </Divider>

            <Form.Item label='Username' name='idexx_username' initialValue={hospital?.idexx_username || null}>
                <Input />
            </Form.Item>

            <Form.Item label='Password' name='idexx_password' initialValue={hospital?.idexx_password || null}>
                <Input />
            </Form.Item>

            <Form.Item label='IVLS Serial #' name='idexx_ivls_serial_number' initialValue={hospital?.idexx_ivls_serial_number || null}>
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Webpacs
            </Divider>

            <Form.Item label='Username' name='webpacs_username' initialValue={hospital?.webpacs_username || null}>
                <Input />
            </Form.Item>

            <Form.Item label='Password' name='webpacs_password' initialValue={hospital?.webpacs_password || null}>
                <Input />
            </Form.Item>

            <Form.Item label='Location Token' name='webpacs_location_token' initialValue={hospital?.webpacs_location_token || null}>
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Ring Central
            </Divider>

            <Form.Item label='Main Line Ext #' name='ring_central_extension_id' initialValue={hospital?.ring_central_extension_id || null}>
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Qualtrix
            </Divider>

            <Form.Item label='Survey Code' name='survey_code' initialValue={hospital?.survey_code || null}>
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Label Printers
            </Divider>

            <Form.Item label='Cage Printer ID' name='patient_label_printer_id' initialValue={hospital?.patient_label_printer_id || null}>
                <Input />
            </Form.Item>

            <Form.Item
                label='RX Printer ID'
                name='prescription_label_printer_id'
                initialValue={hospital?.prescription_label_printer_id || null}
            >
                <Input />
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Credit Card Processing
            </Divider>

            <Form.Item
                label='Stripe Acct ID'
                dependencies={['stripe_public_key']}
                name='stripe_account_id'
                initialValue={stripeAccountIdDisplay}
                rules={[
                    {
                        required: resetStripeAccountId ? true : false,
                        message: 'Please ensure the Stripe Acct ID has been entered.',
                    },
                    {
                        validator: (_, value) => {
                            if (!hasSavedStripeDetails) {
                                if (!value && form.getFieldValue('stripe_public_key')) {
                                    return Promise.reject(new Error('Stripe account ID is needed.'));
                                }
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <Input
                    disabled={!hasSavedStripeDetails ? false : !resetStripeAccountId}
                    addonAfter={
                        !hasSavedStripeDetails ? null : (
                            <Button
                                type='link'
                                style={{ margin: 0, padding: 0, height: 'auto' }}
                                onClick={() => {
                                    setResetStripeAccountId(!resetStripeAccountId);
                                    if (!resetStripeAccountId) {
                                        form.setFieldValue('stripe_account_id', '');
                                    } else {
                                        form.resetFields(['stripe_account_id']);
                                    }
                                    form.validateFields();
                                }}
                            >
                                {resetStripeAccountId ? 'cancel' : 'reset'}
                            </Button>
                        )
                    }
                />
            </Form.Item>
            <Form.Item
                label='Stripe Public Key'
                dependencies={['stripe_account_id']}
                name='stripe_public_key'
                initialValue={stripePublicKeyDisplay}
                rules={[
                    {
                        required: resetStripePublicKey ? true : false,
                        message: 'Please ensure the Stripe Public Key has been entered.',
                    },
                    {
                        validator: (_, value) => {
                            if (!hasSavedStripeDetails) {
                                if (!value && form.getFieldValue('stripe_account_id')) {
                                    return Promise.reject(new Error('Stripe public key is needed.'));
                                }
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <Input
                    disabled={!hasSavedStripeDetails ? false : !resetStripePublicKey}
                    addonAfter={
                        !hasSavedStripeDetails ? null : (
                            <Button
                                type='link'
                                style={{ margin: 0, padding: 0, height: 'auto' }}
                                onClick={() => {
                                    setResetStripePublicKey(!resetStripePublicKey);
                                    if (!resetStripePublicKey) {
                                        form.setFieldValue('stripe_public_key', '');
                                    } else {
                                        form.resetFields(['stripe_public_key']);
                                    }
                                    form.validateFields();
                                }}
                            >
                                {resetStripePublicKey ? 'cancel' : 'reset'}
                            </Button>
                        )
                    }
                />
            </Form.Item>
        </>
    );
};

export default IntegrationsTabPane;
